import * as React from "react"
import Navbar from "./Common/Navbar";
import HomePage from "./HomePage/HomePage";
import Footer from "./Common/Footer";

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "'Open Sans', sans-serif",
  backgroundColor: '#18182a',
  position: 'relative',
}

//backgroundColor: '#1e1c29',

//Navbar
//TitleSection
//BlueChipSection
//LowFeeSection
//MobileSection
//EarnMoreSection
//FutureSection
//GetStartedSection
//WhitepaperSection
//SocialsSection
//Footer

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Navbar/>
      <HomePage/>
      <Footer/>
    </main>
  )
}

export default IndexPage
